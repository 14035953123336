export default {
  Name: '',
  Slug: '',
  OtherName: '',
  Introduction: '',
  HeaderText: '',
  Content: '',
  Attachment: '',
  Categories: [],
  Tags: [],
  Status: 1,
  Sort: 0,
  EnableResponseContent: 0,
  SEO: {
    Description: '',
    Keywords: ''
  },
  Variable: {
    _v: 0,
    BusinessWeekDay: [],
    StoreType: [],
    StoreProperty: [],
  },
  Cover: '',
  Carousel: [],
  Gallery: [],
  OverViewImages: [],
  LanguageData: {},
  Permission: {
    Enable: false,
    MemberRequired: false,
    Level: [],
    Class: [],
    Tags: []
  },
  ResponseData: {
    Mobile: {
      Content: ''
    },
    Web: {
      Content: ''
    }
  }
}
